import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Blue, colors, H1White, Btn1Blue } from "../../../styles/helpers"

const getData = graphql`
  {
    location: wp {
      acfOptionsSiteWideSettings {
        acfSiteWideSettings {
          location
          googleMapUrl
          locationImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1500)
              }
            }
          }
        }
      }
    }
  }
`

const Location = ({ data }) => {
  const locationData = useStaticQuery(getData)
  if (!data.displayLocation) return null

  const image = getImage(
    locationData.location.acfOptionsSiteWideSettings.acfSiteWideSettings
      .locationImage.localFile.childImageSharp.gatsbyImageData
  )
  const logoAlt =
    locationData.location.acfOptionsSiteWideSettings.acfSiteWideSettings
      .locationImage.altText

  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="location-map">
          <div className="location-map__wrapper">
            <GatsbyImage image={image} alt={logoAlt} layout="fixed" />
          </div>
        </div>
        <div className="location-content">
          <h2>Our Location</h2>
          <div
            className="location-content__paragraph"
            dangerouslySetInnerHTML={{
              __html:
                locationData.location.acfOptionsSiteWideSettings
                  .acfSiteWideSettings.location,
            }}
          />
          <div className="location-content__button">
            <a
              target="_blank"
              rel="noreferrer"
              href={
                locationData.location.acfOptionsSiteWideSettings
                  .acfSiteWideSettings.googleMapUrl
              }
            >
              Get Directions
            </a>
          </div>
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  background-color: rgba(217, 201, 158, 0.6);

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .location-map {
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(60%);
      height: 40rem;
    }

    @media (min-width: 1025px) {
      width: calc(60%);
      height: 35rem;
    }

    &__wrapper {
      @media (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .gatsby-image-wrapper {
        @media (min-width: 768px) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .location-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 3rem 2rem;

    @media (min-width: 768px) {
      width: calc(40%);
      padding: 1rem 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(40%);
      margin-right: auto;
    }

    @media (min-width: 1250px) {
      max-width: 40rem;
    }

    h2 {
      ${H1White};
      padding: 0 1rem;
      background-color: ${colors.colorPrimary};
      text-align: center;
    }

    &__paragraph {
      width: 100%;
      text-align: center;

      p {
        ${B1Blue};
        font-weight: bold;

        &:last-of-type {
          margin: 0;
        }
      }
    }

    &__button {
      width: 100%;
      margin-top: 1rem;
      text-align: center;

      a {
        ${Btn1Blue};
      }
    }
  }
`

export default Location
