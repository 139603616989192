import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  colors,
  H1White,
  B1White,
  medWrapper,
  Btn1Clear,
} from "../../../styles/helpers"

const LeaveReview = ({ data }) => {
  const imageDisplay = getImage(
    data.leaveReviewGraphic.localFile.childImageSharp.gatsbyImageData
  )
  const imageAlt = data.leaveReviewGraphic.altText
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="content">
          <div className="title">
            <h2>{data.leaveReviewTitle}</h2>
          </div>
          <div className="para">
            <p>{data.leaveReviewContent}</p>
          </div>
          <div className="button">
            <a target="_blank" rel="noreferrer" href={data.leaveReviewUrl}>
              {data.leaveReviewButtonText}
            </a>
          </div>
        </div>

        <div className="image">
          <GatsbyImage
            image={imageDisplay}
            alt={imageAlt}
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
          />
        </div>
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  padding: 1rem 0;
  background-color: ${colors.colorPrimary};

  .wrapper {
    ${medWrapper};
    align-items: center;
  }

  .content {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(40%);
    }

    .title {
      h2 {
        ${H1White};
      }
    }

    .para {
      p {
        ${B1White};
      }
    }

    .button {
      a {
        ${Btn1Clear};
      }
    }
  }

  .image {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(60%);
    }
  }
`

export default LeaveReview
