import React from "react"
import styled from "styled-components"
import { colors, H1Blue, standardWrapper } from "../../../styles/helpers"
import BackgroundOne from "../../Icons/BackgroundOne"

const OfficeHours = ({ data }) => {
  return (
    <SectionStyled>
      <div className="wrapper">
        <div className="title">
          <h2>Office Hours</h2>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.officeHours }}
        />
      </div>
      <div className="bg-one">
        <BackgroundOne />
      </div>
    </SectionStyled>
  )
}

const SectionStyled = styled.section`
  position: relative;
  padding: 5rem 0;

  @media (min-width: 768px) {
    padding: 10rem 0;
  }

  @media (min-width: 1025px) {
    padding: 15rem 0;
  }

  .wrapper {
    ${standardWrapper};
    align-items: center;
  }

  .title {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(30%);
    }

    h2 {
      ${H1Blue};
      font-weight: bold;
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(70% - 3rem);
      margin-left: 3rem;
      padding-left: 3rem;
      border-left: 1.5rem solid ${colors.colorPrimary};
    }

    p {
      ${H1Blue};
      font-weight: normal;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .bg-one {
    position: absolute;
    bottom: -25rem;
    right: -30rem;
    max-width: 40rem;
    margin: auto;
    z-index: -1;

    @media (min-width: 768px) {
      bottom: -25rem;
      right: -30rem;
    }

    @media (min-width: 1025px) {
      bottom: -25rem;
      right: -25rem;
    }

    @media (min-width: 1200px) {
      bottom: -25rem;
      right: -20rem;
    }
  }
`

export default OfficeHours
