import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Hero from "../components/templates/common/Hero"
import ContactForm from "../components/templates/contact/ContactForm"
import BeigeBlock from "../components/templates/contact/BeigeBlock"
import OfficeHours from "../components/templates/contact/OfficeHours"
import LeaveReview from "../components/templates/contact/LeaveReview"
import Location from "../components/templates/common/Location"

const Contact = props => {
  const {
    hero,
    contactForm,
    beigeBlock,
    officeHours,
    leaveReview,
    location,
    seoInfo,
  } = props.data

  const heroData = hero.template.templatePageContact
  const contactFormData = contactForm.template.templatePageContact
  const beigeBlockData = beigeBlock.template.templatePageContact
  const officeHoursData = officeHours.template.templatePageContact
  const leaveReviewData = leaveReview.template.templatePageContact
  const locationData = location.template.templatePageContact

  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <Hero data={heroData} />
      <ContactForm data={contactFormData} />
      <BeigeBlock data={beigeBlockData} />
      <OfficeHours data={officeHoursData} />
      <LeaveReview data={leaveReviewData} />
      <Location data={locationData} />
    </Layout>
  )
}

export const contactTempQuery = graphql`
  query contactTempPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    hero: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          templatePageContact {
            heroImage {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 2500)
                }
              }
            }

            heroLogo {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    contactForm: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          templatePageContact {
            displayContactForm
          }
        }
      }
    }

    beigeBlock: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          templatePageContact {
            beigeBlockContent
            beigeBlockIcon {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }

    officeHours: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          templatePageContact {
            officeHours
          }
        }
      }
    }

    leaveReview: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          templatePageContact {
            leaveReviewTitle
            leaveReviewContent
            leaveReviewButtonText
            leaveReviewUrl
            leaveReviewGraphic {
              altText
              sourceUrl
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(width: 1000)
                }
              }
            }
          }
        }
      }
    }

    location: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          templatePageContact {
            displayLocation
          }
        }
      }
    }
  }
`

export default Contact
